import React, { useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import ErrorBounday from "./components/error-boundary.component";
import Spinner from "./components/spinner.component";
// import Cookies from "js-cookie";

const Home = lazy(() => import("./pages/home/Home"));
const ProfileView = lazy(() => import("./pages/home/ProfileView"));
const PageNotFound = lazy(() => import("./pages/home/PageNotFound"));
const Privacy = lazy(() => import("./pages/home/Privacy"));
const TermsAndConditions = lazy(() => import("./pages/home/TermsAndConditions"));
const Demo = lazy(() => import("./pages/home/QRCodeProfileView"));

function App() {
  const queryParams = window.location.href;
  let length1 = window.location.href.split('/').length;
  length1 = (length1 - 1)
  let userProfileLink = window.location.href.split('/')[length1];

  return (
    <main>
      <Router>
        <ErrorBounday>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path="*"
                element={<PageNotFound />}
              />
              <Route
                exact
                path="/privacy"
                element={<Privacy />}
              />
              <Route
                exact
                path="/termsAndConditions"
                element={<TermsAndConditions />}
              />
               <Route
                exact
                path="/profile/:id"
                element={<Demo />}
              />
              <Route
                exact
                path="/:id"
                element={<ProfileView />}
              />
            </Routes>
          </Suspense>
        </ErrorBounday>
      </Router>
    </main>
  );
}

export default App;
